<template>
    <div class="wrapper py-5 mb-5">
        <v-container fluid>
            <v-card class="">
                <div class="d-flex align-center w-full mr-4 px-4 py-4">
                    <avatar-rolles
                        access-status="client"
                        :firstname="batch.client.firstname"
                        :lastname="batch.client.lastname"
                        avatar-size="75"
                    />
                    <div class="ml-2">
                        <router-link
                            class="font-weight-bold mb-0"
                            style="color: black"
                            :to="{
                                name: 'hr-clients-details',
                                params: { id: batch.client.id },
                            }"
                        >
                            {{ nameCapitalized(batch.client.firstname) }}
                            {{ nameCapitalized(batch.client.lastname) }}
                        </router-link>
                        <p class="grey--text mb-0 d-flex align-center">
                            {{ batch.client.id }}
                            {{ batch.client.country && ',' }}
                            <span :class="'ml-1 flag-icon flag-input-icon flag-icon-' + getCountre" />
                        </p>
                    </div>
                </div>
            </v-card>
            <div class="py-5">
                <v-card
                    elevation="2"
                    class="px-0 py-0 d-flex mb-5"
                    style="overflow-x: auto;"
                >
                    <!-- Dates : START -->
                    <div class="extras-row flex-column">
                        <div class="d-flex align-center extras-row_col extras-row_col-title">
                            <div class="d-flex align-center">
                                <v-icon class="mr-4">
                                    mdi-calendar-week
                                </v-icon>
                                <span class="font-weight-bold text-uppercase text-subtitle-2">
                                    Dates
                                </span>
                            </div>
                        </div>
                        <!-- Created: -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Created:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ $date.humanizeDate(batch.created_at) }}
                                    </span>
                                    <span class="font-weight-bold">
                                        ({{ $date.dateFromNow(batch.created_at) }})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- Updated: -->
                        <div class="extras-row_col">
                            <div v-if="batch.updated_at">
                                <p class="grey--text mb-0 text-body-2">
                                    Updated:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ $date.humanizeDate(batch.updated_at) }}
                                    </span>
                                    <span class="font-weight-bold">
                                        ({{ $date.dateFromNow(batch.updated_at) }})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- Paid: -->
                        <div class="extras-row_col">
                            <div v-if="batch.paid_at">
                                <p class="grey--text mb-0 text-body-2">
                                    Paid:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ $date.humanizeDate(batch.paid_at) }}
                                    </span>
                                    <span class="font-weight-bold">
                                        ({{ $date.dateFromNow(batch.paid_at) }})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- Deleted: -->
                        <div class="extras-row_col">
                            <div v-if="batch.deleted_at">
                                <p class="grey--text mb-0 text-body-2">
                                    Deleted:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ $date.humanizeDate(batch.deleted_at) }}
                                    </span>
                                    <span class="font-weight-bold">
                                        ({{ $date.dateFromNow(batch.deleted_at) }})
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Dates : END -->
                    <!-- Payment : START -->
                    <div class="extras-row flex-column">
                        <div class="d-flex align-center extras-row_col extras-row_col-title">
                            <div class="d-flex align-center">
                                <v-icon class="mr-4">
                                    mdi-credit-card-outline
                                </v-icon>
                                <span class="font-weight-bold text-uppercase text-subtitle-2">
                                    Payment
                                </span>
                            </div>
                        </div>
                        <!-- Pay method: -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Pay method :
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ getPaymethod(batch.paymethod) || '—' }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Pay method ID :
                                </p>
                                <div class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <div
                                        v-if="batch.paymentid"
                                        class="d-flex"
                                        style="width: 125px"
                                    >
                                        <v-tooltip
                                            top
                                            style="margin-right: 5px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    class="d-inline-block tw-truncate font-weight-bold"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    {{ batch.paymentid }};
                                                </div>
                                            </template>
                                            <div>{{ batch.paymentid }}</div>
                                        </v-tooltip>
                                        <copy-to-click-board
                                            :copy-string="batch.paymentid"
                                            icon
                                            style="margin-left: 5px"
                                            @click.stop
                                        />
                                    </div>
                                    <span
                                        v-else
                                        class="mr-2"
                                    >
                                        —
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- card_holder_name: -->
                        <div
                            v-if="batch.card_holder_name"
                            class="extras-row_col"
                        >
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Card holder name :
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ batch.card_holder_name }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- status: -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Status :
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ batch.status }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Payment : END -->
                    <!-- Total : START -->
                    <div class="extras-row flex-column">
                        <div class="d-flex align-center extras-row_col extras-row_col-title">
                            <div class="d-flex align-center">
                                <v-icon class="mr-4">
                                    mdi-account-cash
                                </v-icon>
                                <span class="font-weight-bold text-uppercase text-subtitle-2">
                                    Total
                                </span>
                            </div>
                        </div>
                        <!-- Total: -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Total:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ batch.total | money }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- store_credit: -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Store credit:
                                </p>
                                <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                    <span
                                        class="font-weight-bold"
                                        style="white-space: nowrap"
                                    >
                                        {{ batch.store_credit | money }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Total : END -->
                    <!-- Orders : START -->
                    <div
                        v-if="batch.orders.length"
                        class="extras-row flex-column"
                    >
                        <div class="d-flex align-center extras-row_col extras-row_col-title">
                            <div class="d-flex align-center">
                                <v-icon class="mr-4">
                                    mdi-barcode
                                </v-icon>
                                <span class="font-weight-bold text-uppercase text-subtitle-2">
                                    Orders
                                </span>
                            </div>
                        </div>
                        <div
                            class="extras-row_col"
                        >
                            <div
                                v-for="(order, index) in batch.orders"
                                :key="index"
                            >
                                <div
                                    class="d-flex align-end"
                                    style="gap: 40px"
                                >
                                    <span>{{ index+1 }}.</span>
                                    <div>
                                        <p class="grey--text mb-0 text-body-2">
                                            Order ID:
                                        </p>
                                        <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                            <router-link
                                                :to="{
                                                    name: 'order-details',
                                                    params: {
                                                        id: order.orderid
                                                    }
                                                }"
                                                class="font-weight-bold"
                                                style="white-space: nowrap; color: black"
                                            >
                                                {{ order.orderid }}
                                            </router-link>
                                            <information-tooltip>
                                                <div>
                                                    <div class="mb-1">
                                                        <p class="mb-0 text-subtitle-2 font-weight-regular">
                                                            Type:
                                                        </p>
                                                        <p class="mb-0 d-flex text-body-2">
                                                            {{ order.type }}
                                                        </p>
                                                    </div>
                                                    <div class="mb-1">
                                                        <p class="mb-0 text-subtitle-2 font-weight-regular">
                                                            Title:
                                                        </p>
                                                        <p class="mb-0 d-flex text-body-2">
                                                            {{ order.title }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </information-tooltip>
                                        </p>
                                    </div>
                                    <div>
                                        <p
                                            class="grey--text mb-0 text-body-2"
                                            style="white-space: nowrap"
                                        >
                                            Discount rate:
                                        </p>
                                        <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                            <span
                                                class="font-weight-bold"
                                                style="white-space: nowrap"
                                            >
                                                {{ order.discount_rate }}%
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="grey--text mb-0 text-body-2">
                                            Total:
                                        </p>
                                        <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                            <span
                                                class="font-weight-bold"
                                                style="white-space: nowrap"
                                            >
                                                {{ order.total | money }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Orders : END -->
                </v-card>
            </div>
        </v-container>
    </div>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'
import { ACTION_GET_BATCH } from '@/store/modules/batch/action-types';

import { createNamespacedHelpers } from 'vuex';
import { nameCapitalized } from '@/helpers/capitalized/index'
import InformationTooltip from '@/components/shared/InformationTooltip.vue';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles.vue';
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard.vue';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('batch');
export default {
    components: {
        CopyToClickBoard,
        AvatarRolles,
        InformationTooltip
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            batch: null
        }
    },
    metaInfo: {
        title: 'Batch Details'
    },
    computed: {
        getCountre() {
            return this.batch.client?.country?.toLowerCase()
        }
    },
    async activated() {
        await this.getBatch()
    },
    methods: {
        nameCapitalized,
        ...mapAdminActions({
            ACTION_GET_BATCH
        }),
        async getBatch() {
            const { id } = this.$route.params
            const data = await this[ACTION_GET_BATCH]({ batchid: id })
            this.batch = data
        },
        getPaymethod(paymethod) {
            if (paymethod === 'paypal') {
                return 'Paypal';
            } if (paymethod === 'cc') {
                return 'Credit card';
            } if (paymethod === 'stripe') {
                return 'Stripe';
            } if (paymethod === null && paymethod === 'store_credit') {
                return 'Store credit';
            }
            return paymethod
        }
    }
}
</script>

<style>

</style>
