<template>
    <v-container fluid>
        <v-row class="white rounded pa-2">
            <div class="d-flex w-full">
                <div class="mr-auto">
                    <p class="grey--text mb-2">
                        Order ID:
                    </p>
                    <div class="d-flex align-center">
                        <router-link
                            :to="{
                                name: 'order-details',
                                params: {
                                    id: feedback.orderid
                                }
                            }"
                            class="font-weight-bold black--text text-decoration-none mr-2"
                        >
                            {{ feedback.orderid }}
                        </router-link>
                        <copy-to-click-board
                            :copy-string="feedback.orderid"
                            icon
                        />
                    </div>
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Domain:
                    </p>
                    <div class="d-flex align-center">
                        <domain-favicon
                            :domain="feedback.domain"
                        />
                        <span class="">
                            {{ feedback.domain }}
                        </span>
                    </div>
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Writer:
                    </p>
                    <writer-avatar
                        v-if="feedback.writer_firstname"
                        :id="feedback.sw_id"
                        :feedbacks="feedback.fb_reviews"
                        :rating="feedback.writer_rank"
                        :rating_rc="feedback.rank_rc"
                        :applied="feedback.ts_apply"
                        :status="feedback.writer_trust_status"
                        :firstname="feedback.writer_firstname"
                        :lastname="feedback.writer_lastname"
                        :avatar-url="feedback.avatar_url"
                        :avatar-fallback="feedback.avatar_fallback"
                        :order_limit="feedback.projects_limit"
                        :country="feedback.writer_country"
                    />
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Client:
                    </p>
                    <client-avatar
                        v-if="feedback.client_firstname"
                        :id="feedback.clientid"
                        :is-client="true"
                        :orders-count="feedback.clients_statistics.orders_total"
                        :client-country="feedback.client_country"
                        :firstname="feedback.client_firstname"
                        :lastname="feedback.client_lastname"
                        :avatar="feedback.client_avatar"
                    />
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Rate writer:
                    </p>
                    <marks
                        :rate="feedback.rate_writer"
                        title="Rate writer"
                    />
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Rate company:
                    </p>
                    <marks
                        :rate="feedback.rate_company"
                        title="Rate company"
                    />
                </div>
                <div class="mr-7">
                    <p class="grey--text mb-2">
                        Status:
                    </p>
                    <status-chip :feedback="feedback" />
                </div>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import Marks from '@/components/Feedback/FeedbackTable/Marks'
import StatusChip from '@/components/Feedback/FeedbackTable/StatusChip'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'

import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

export default {
    components: {
        Marks,
        StatusChip,
        CopyToClickBoard,
        ClientAvatar,
        WriterAvatar,
        DomainFavicon
    },
    props: {
        feedback: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        }
    }
}
</script>

<style>

</style>
