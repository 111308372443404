<template>
    <v-container
        fluid
        class="white rounded align-center px-0 py-0 mb-16 overflow--hidden"
    >
        <div>
            <!-- Dates -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Dates
                        </span>
                    </div>
                </div>
                <!-- Created -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Created:
                    </p>
                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                        {{ feedback.created_at ? $date.fullDate(feedback.created_at) : '—' }}
                    </p>
                </div>
                <!-- Updated -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Updated:
                    </p>
                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                        {{ feedback.updated_at ? $date.fullDate(feedback.updated_at) : '—' }}
                    </p>
                </div>
            </div>
            <!-- Communication -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Communication
                        </span>
                    </div>
                </div>
                <!-- Communication -->
                <div class="extras-row_col">
                    <div class="d-flex align-center">
                        <p class="mb-0 text-body-2 ml-2">
                            <marks-chip :rate="+feedback.delivery" />
                        </p>
                    </div>
                </div>
                <!-- Communication details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="grey--text mb-0 text-body-2">
                            Communication details:
                        </p>
                        <p class="mb-0 text-body-2">
                            {{ feedback.communication_details || '—' }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Content -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Content
                        </span>
                    </div>
                </div>
                <!-- content -->
                <div class="extras-row_col">
                    <div class="d-flex align-center">
                        <p class="mb-0 text-body-2 ml-2">
                            <marks-chip :rate="+feedback.content" />
                        </p>
                    </div>
                </div>
                <!-- content details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="grey--text mb-0 text-body-2">
                            Content details:
                        </p>
                        <p class="mb-0 text-body-2">
                            {{ feedback.content_details || '—' }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Delivery -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Delivery
                        </span>
                    </div>
                </div>
                <!-- delivery -->
                <div class="extras-row_col">
                    <div class="d-flex align-center">
                        <p class="mb-0 text-body-2 ml-2">
                            <marks-chip :rate="+feedback.delivery" />
                        </p>
                    </div>
                </div>
                <!-- delivery details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="grey--text mb-0 text-body-2">
                            Delivery details:
                        </p>
                        <p class="mb-0 text-body-2">
                            {{ feedback.delivery_details || '—' }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Support -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Support
                        </span>
                    </div>
                </div>
                <!-- support -->
                <div class="extras-row_col">
                    <div class="d-flex align-center">
                        <p class="mb-0 text-body-2 ml-2">
                            <marks-chip :rate="+feedback.support" />
                        </p>
                    </div>
                </div>
                <!-- support details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="grey--text mb-0 text-body-2">
                            Support details:
                        </p>
                        <p class="mb-0 text-body-2">
                            {{ feedback.support_details || '—' }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Website -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Website
                        </span>
                    </div>
                </div>
                <!-- website -->
                <div class="extras-row_col">
                    <div class="d-flex align-center">
                        <p class="mb-0 text-body-2 ml-2">
                            <marks-chip :rate="+feedback.website" />
                        </p>
                    </div>
                </div>
                <!-- website details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="grey--text mb-0 text-body-2">
                            Website details:
                        </p>
                        <p class="mb-0 text-body-2">
                            {{ feedback.website_details || '—' }}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Details -->
            <div class="extras-row">
                <!-- row title -->
                <div class=" extras-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Details
                        </span>
                    </div>
                </div>
                <!-- details details -->
                <div class="extras-row_col extras-row_col--auto">
                    <div class="">
                        <p class="mb-0 text-body-2">
                            {{ feedback.details }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip'

export default {
    components: { MarksChip },
    props: {
        feedback: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDetails: false
        }
    }
}
</script>
