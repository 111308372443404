<template>
    <div>
        <v-avatar
            :color="ratingUI.bg"
            size="43"
        >
            <div :class="`font-weight-bold text-body-2 ${ratingUI.text}`">
                {{ rate || '0.00' }}
            </div>
        </v-avatar>
    </div>
</template>
<script>
export default {
    name: 'Marks',
    props: {
        rate: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: false
        }
    },
    computed: {
        ratingUI() {
            switch (true) {
            case (this.rate >= 9): {
                return {
                    bg: 'rgba(39, 217, 31, 0.1)',
                    text: 'success--text'
                }
            }
            case (this.rate < 5): {
                return {
                    bg: 'rgba(255, 0, 0, 0.1)',
                    text: 'error--text'
                }
            }
            default: {
                return {
                    bg: 'rgba(240, 125, 0, 0.1)',
                    text: 'accent--text'
                }
            }
            }
        }

    }
}
</script>
<style lang="scss">
.mark-title {
    white-space: nowrap;
}
</style>
