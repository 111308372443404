<template>
    <div class="wrapper py-5 feedback-page">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <the-filter-preset
                        :active-preset="searchOptions.preset"
                        :presets="getterPresets"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>

            <feedback-table
                :search-options="searchOptions"
                @menuActivated="onMenuActivated"
                @updateOptions="updateOptions"
            />

            <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterFeedbacksList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <toolbar-menu
                v-if="selected.length"
                :toolbars="multiToolbars"
                @toolbarActivated="onToolbarActivated"
            />

            <component
                :is="modalComponent"
                :feedback="currentFeedback"
                :title="modal.title"
                :feedback-statuses="statuses"
                :current-status="currentFeedback.status"
                :description="modal.description"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

import {
    FEEDBACKS_MULTI_TOOLBAR, FEEDBACKS_TABLE_HEADER, FEEDBACKS_TABLE_HEADER_TOTAL, FEEDBACKS_TOOLBAR, STATUSES
} from '@/constants/Feedbacks'
import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'
import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST,
    ACTION_SET_STATUS_FEEDBACK,
    ACTION_GET_FEEDBACKS_TOTALS
} from '@/store/modules/feedbacks/action-types';

import { CLEAR_FEEDBACKS_LIST } from '@/store/modules/feedbacks/mutation-types';

import ModalFeedbackEdit from '@/components/shared/Modals/ModalFeedbackEdit'
import ModalFeedbackCreateTicket from '@/components/shared/Modals/ModalFeedbackCreateTicket'
import ModalFeedbackStatus from '@/components/shared/Modals/ModalFeedbackStatus'
import StatusChip from '@/components/Feedback/FeedbackTable/StatusChip';
import Marks from '@/components/Feedback/FeedbackTable/Marks';

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip'
import FeedbackTable from '@/components/Feedback/FeedbackTable/FeedbackTable';

import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'

import GeneralExtras from '@/components/Feedback/FeedbackTable/GeneralExtras';
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import InformationTooltip from '@/components/shared/InformationTooltip'

const {
    mapActions: mapFeedbacksActions,
    mapMutations: mapFeedbacksMutations
} = createNamespacedHelpers('feedbacks');

export default {
    name: 'Feedbacks',
    components: {
        ModalFeedbackEdit,
        ModalFeedbackStatus,
        ModalFeedbackCreateTicket,
        ToolbarMenu,
        StatusChip,
        GeneralExtras,
        Marks,
        TheMainFilter,
        TheFilterPreset,
        CopyToClickBoard,
        PaginationWithPerpage,
        TableSkeleton,
        ClientAvatar,
        WriterAvatar,
        DomainFavicon,
        TableRowsMenu,
        TableLoader,
        InformationTooltip,
        MarksChip,
        FeedbackTable
    },
    data() {
        return {
            isFirstLoading: true,
            selected: [],
            modal: {},
            currentFeedback: {},
            actionName: '',
            options: {},
            isFirstLoad: true,
            openedRow: [],
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: 'sw_id',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            search_fields: null,
            savedScroll: { x: 0, y: 0 },
            singleAction: false
        }
    },
    metaInfo: {
        title: 'Feedbacks'
    },
    computed: {
        ...mapGetters('feedbacks', [
            'getterFeedbacksList',
            'getterLastPage',
            'getterTotal',
            'getterPresets',
            'getterTotalsLoading',
            'getterListLoading'
        ]),
        tableHeader() {
            return FEEDBACKS_TABLE_HEADER
        },
        tableHeaderTotal() {
            return FEEDBACKS_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return FEEDBACKS_TABLE_HEADER.length + 1
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        statuses() {
            return STATUSES
        },
        itemToolbars() {
            return FEEDBACKS_TOOLBAR
        },
        multiToolbars() {
            return FEEDBACKS_MULTI_TOOLBAR
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        }
    },
    async activated() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        })
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        requests.push(this.getFeedbacksList())
        await Promise.allSettled(requests)
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        if (!to.name.includes('feedbacks')) {
            this[CLEAR_FEEDBACKS_LIST]()
        }
        next()
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('feedback')
        },
        ...mapFeedbacksActions({
            ACTION_GET_FEEDBACKS_LIST,
            ACTION_EDIT_FEEDBACK,
            ACTION_CREATE_TICKET_FEEDBACK,
            ACTION_SET_STATUS_FEEDBACK,
            ACTION_GET_FEEDBACKS_TOTALS
        }),
        ...mapFeedbacksMutations({
            CLEAR_FEEDBACKS_LIST
        }),
        // HOVER ON TABLE ROW
        selectItem(item) {
            this.hoveredItem = item
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        onMenuActivated(feedback, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentFeedback = feedback
        },
        async getFeedbacksList(with_total = true) {
            if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                this.$router.replace({ query: { ...this.searchOptions } })
            }
            try {
                const requestArr = [this[ACTION_GET_FEEDBACKS_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_FEEDBACKS_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        paginationPageEvent(e) {
            this.searchOptions.page = e
            this.getFeedbacksList(false)
        },
        onFilterTable(payload) {
            const newObj = Object.entries(payload).reduce((acc, curr) => {
                acc[curr[0]] = this.searchOptions[curr[0]] || ''
                return acc
            }, {})
            if (JSON.stringify(payload) !== JSON.stringify(newObj)) {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
                this.getFeedbacksList()
            }
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getFeedbacksList()
            }
        },
        onCloseModal() {
            this.modal = {}
            this.currentFeedback = {}
            this.actionName = ''
        },
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        getSelectedFeedbackID() {
            if (this.selected.length && !this.currentFeedback.testimonialid) {
                return this.selected.map((element) => element.testimonialid)
            }
            return [this.currentFeedback.testimonialid]
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'edit_feedback':
                    await this.editFeedback({ payload, id: this.currentFeedback.testimonialid })
                    break;
                case 'set_status':
                    await this.setStatus({ payload, id: this.getSelectedFeedbackID() })
                    break;
                case 'contact_buyer':
                    await this.contactBuyer(payload)
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async editFeedback({ payload, id }) {
            await this[ACTION_EDIT_FEEDBACK]({ payload, id })
        },
        async setStatus({ payload, id }) {
            await this[ACTION_SET_STATUS_FEEDBACK]({ payload, id })
        },
        async contactBuyer(payload) {
            await this[ACTION_CREATE_TICKET_FEEDBACK](payload)
        },
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        getAverageRating(item) {
            let rate = 0
            if (item.parsed_testimonial_new) {
                const {
                    communication, content, delivery, support, website
                } = item.parsed_testimonial_new
                rate = (Number(communication) + Number(content) + Number(delivery) + Number(support) + Number(website)) / 5
            }
            if (item.parsed_testimonial_old) {
                const {
                    rate_writer, rate_company
                } = item.parsed_testimonial_new
                rate = (Number(rate_writer) + Number(rate_company)) / 2
            }
            return rate
        }
    }
}
</script>
<style lang="scss">
.feedback-details{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cursor--pointer{
    cursor: pointer;
}
.feedback_card-details {
    max-width: 250px;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
