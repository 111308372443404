<template>
    <div
        v-if="feedback"
        class="wrapper py-5 mb-5"
    >
        <feedback-details-top
            :feedback="feedback"
        />

        <feedback-details-info
            :feedback="feedback"
            class="mt-5"
        />

        <toolbar-menu
            :toolbars="toolbars"
            @toolbarActivated="onToolbarActivated"
        />

        <component
            :is="modalComponent"
            :feedback="feedback"
            :title="modal.title"
            :feedback-statuses="statuses"
            :current-status="feedback.status"
            :description="modal.description"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
// import Api from '@/helpers/api/index.js'
import FeedbackDetailsTop from '@/components/Feedback/FeedbackDetails/FeedbackDetailsTop'
import FeedbackDetailsInfo from '@/components/Feedback/FeedbackDetails/FeedbackDetailsInfo'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

// MODALS
import ModalFeedbackEdit from '@/components/shared/Modals/ModalFeedbackEdit'
import ModalFeedbackCreateTicket from '@/components/shared/Modals/ModalFeedbackCreateTicket'
import ModalFeedbackStatus from '@/components/shared/Modals/ModalFeedbackStatus'

import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FETCH_FEEDBACK,
    ACTION_SET_STATUS_FEEDBACK
} from '@/store/modules/feedbacks/action-types';

import {
    FEEDBACKS_TOOLBAR, STATUSES
} from '@/constants/Feedbacks'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('feedbacks');

export default {
    components: {
        FeedbackDetailsTop,
        FeedbackDetailsInfo,
        ToolbarMenu,
        // MODALS
        ModalFeedbackEdit,
        ModalFeedbackStatus,
        ModalFeedbackCreateTicket
    },
    data() {
        return {
            openedModal: '',
            modal: {},
            feedback: null,
            fileTypes: []
        }
    },
    metaInfo: {
        title: 'Feedback Details'
    },
    computed: {
        toolbars() {
            return FEEDBACKS_TOOLBAR
        },
        statuses() {
            return STATUSES
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    async created() {
        await this.getFeedback()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_FETCH_FEEDBACK,
            ACTION_EDIT_FEEDBACK,
            ACTION_CREATE_TICKET_FEEDBACK,
            ACTION_SET_STATUS_FEEDBACK
        }),
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        async getFeedback() {
            const { id } = this.$route.params
            const data = await this[ACTION_GET_FETCH_FEEDBACK]({ id })
            this.feedback = data
        },
        onCloseModal() {
            this.modal = {}
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'edit_feedback':
                    await this.editFeedback({ payload, id: this.feedback.id })
                    break;
                case 'set_status':
                    await this.setStatus({ payload, id: [this.feedback.id] })
                    break;
                case 'contact_buyer':
                    await this.contactBuyer(payload)
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async editFeedback({ payload, id }) {
            await this[ACTION_EDIT_FEEDBACK]({ payload, id })
            this.feedback = {
                ...this.feedback,
                ...payload
            }
        },
        async setStatus({ payload, id }) {
            await this[ACTION_SET_STATUS_FEEDBACK]({ payload, id })
            this.feedback.status = payload.status
        },
        async contactBuyer(payload) {
            await this[ACTION_CREATE_TICKET_FEEDBACK](payload)
        }
    }
}
</script>

<style>

</style>
